<template>
  <div class="search">
    <div class="image">
      <van-image
        width="100px"
        style="padding: 20px"
        height="100px"
        fit="contain"
        :src="subAccount.url"
      />
    </div>
    <div class="theme-color">
      {{ subAccount.tip }}
    </div>
    <div class="back-tip theme-color" v-if="subAccountState === 3 || subAccountState === 4">
      {{ subAccountStepInfo }}
    </div>
    <van-button
      @click="handleGoSubAccount"
      class="btn"
      :type="subAccountState === 3 || subAccountState === 4 ? 'primary' : undefined"
    >
      {{ subAccount.btn_tip }}
    </van-button>
  </div>
</template>

<script>
import { Image as VanImage, Button } from "vant";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";

export default {
  name: "Home",
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
  },
  data() {
    return {};
  },
  beforeCreate() {
    const { query = {} } = this.$route;
    const { c, e } = query;
    const time = this.$store.state.user.cookieExpires || 0;
    this.$store.dispatch("handleLogin");
    if (Number(e) > Number(time)) {
      this.$util.setCookieValue(c, e);
    }
  },
  methods: {
    handleGoSubAccount() {
      this.$router.push({ path: "/isda/openSubAccount" });
    },
  },
  computed: {
    subAccount() {
      const state = {};
      if (this.subAccountState === 4) {
        state.tip = "抱歉，您的审批被退回！";
        state.btn_tip = "重签开立子账户";
        state.url =
          "https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/evaluation_failed.png";
        return state;
      }
      if (this.subAccountState === 3) {
        state.tip = "恭喜，您的审批已通过！";
        state.btn_tip = "再次开立子账户";
        state.url =
          "https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_success.png";
        return state;
      }
      if (this.subAccountState === 1) {
        state.tip = "已提交!";
        state.btn_tip = "开立子账户查看";
        state.url =
          "https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_processing.png";
        return state;
      }
      state.tip = "开立子账户审批中！";
      state.btn_tip = "开立子账户查看";
      state.url =
        "https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_processing.png";
      return state;
    },
  },
  setup() {
    const subAccountState = ref("");
    const subAccountStepInfo = ref("");
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "开立子账户");
      store.commit("setStepsShow", false);
      const res = await httpApi.getSubAccountState();
      if (res) {
        subAccountState.value = res.data.subAccountState;
        subAccountStepInfo.value = res.data.subAccountStepInfo;
      }
    });
    return {
      subAccountState,
      subAccountStepInfo,
    };
  },
};
</script>

<style lang="less">
.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search {
  padding-left: @space-0;
  padding-right: @space-0;
  .btn {
    width: 90%;
    margin: 30px auto;
  }
  .back-tip {
    margin-top: 30px;
    text-align: left;
    margin-left: 5%;
  }
}
</style>
